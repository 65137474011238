<template>
  <div>
    <div class="previewBox">
<!--      <el-button-->
<!--          circle-->
<!--          class="drawer-close"-->
<!--          icon="el-icon-close"-->
<!--          type="danger"-->
<!--          @click="closeDrawer"-->
<!--      ></el-button>-->
      <div class="videoParent" @click="handleVideoParentClick">
        <video
            autoplay
            controls
            playsinline>
          <source :src='url'
                  type='video/mp4'>
          您的浏览器不支持 video 视频播放
        </video>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "preview",
  props: {
    url: {
      type: String,
      default: ''
    }
  },
  data() {
    return {};
  },
  mounted() {
    // 监听键盘按下事件
    document.addEventListener('keydown', this.handleKeydown);
  },
  beforeDestroy() {
    // 组件销毁前移除事件监听器
    document.removeEventListener('keydown', this.handleKeydown);
  },
  methods: {
    handleVideoParentClick(event) {
      // 检查点击的是否是video元素或其子元素
      if (event.target.classList.contains('videoParent')) {
        this.closeDrawer();
      }
    },
    handleKeydown(event) {
      if (event.key === 'Escape' || event.keyCode === 27) {
        // ESC键被按下
        console.log('ESC键被按下');
        // 在这里执行你想要的操作
        this.closeDrawer();
      }
    },
    closeDrawer() {
      this.$emit('close');
    }
  }
};
</script>
<style lang="scss" scoped>
.previewBox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(222, 222, 222, 0.3);
  z-index: 99999;

  .videoParent {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    video {
      max-height: 70%;
      height: auto;
      width: auto;
      max-width: 50%;
      object-fit: contain;
      display: block;
      margin: 0;
      padding: 0;
      border-radius: 10px;
    }
  }


}

//.drawer-close {
//  position: absolute;
//  top: 60px;
//  right: 40px;
//}

</style>
