import {Message} from 'element-ui'
export default {
    install(Vue, options) {
        Vue.prototype.$success = function (message) {
            Message.success({
                message: message,
                duration: 1000,
            })
        };

        Vue.prototype.$warning = function (message) {
            Message.warning({
                message: message,
                duration: 1000,
            })
        };
    }
}
