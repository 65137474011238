//本地接口
// const requestUrl = 'http://127.0.0.1:10087';
// const sseUrl = 'http://127.0.0.1:10087';

//服务器
const requestUrl = '/api'
const sseUrl = '/join';

const pageSize = 5;
const pageArr = [pageSize, pageSize * 2, pageSize * 3, pageSize * 4, pageSize * 5];

const maxFileSize = 20;
export default {
    pageSize,
    requestUrl,
    sseUrl,
    pageArr,
    maxFileSize
};
