import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        audio: new Audio(),
        userInfo: {}, //当前用户信息
        connectStatus: false  //通信状态
    },
    mutations: {
        setUserInfo(state, data) {
            state.userInfo = data;
        },
        setConnectStatus(state, data) {
            state.connectStatus = data;
        },
        //消息提示音
         playNotice(state) {
            state.audio.src = require('@/assets/medium/notice.mp3');
             state.audio.play();
        },
        //上线提示音
         playOnline(state){
            state.audio.src = require('@/assets/medium/online.mp3');
             state.audio.play();
        },
        //发送消息提示音
        playSend(state){
            state.audio.src = require('@/assets/medium/send.mp3');
            state.audio.play();
        },
    },
    actions: {
        async getUserInfo(state) {
            const {data} = await axios.post('/user/getInfo');
            if (data.code === 200) {
                state.commit('setUserInfo', data.data);
            }
        },
    },
    getters: {
        getUserInfo(state) {
            return state.userInfo;
        },
    }
});
